class CategoryRequestModel {
    category_id

    constructor (data) {
        this.category_id = data.category_id
    }
}

class CategoryResponseModel {
    count
    categories

    constructor (data) {
        this.count = data.count
        this.categories = data.categories
    }
}

class CategoriesResponseModel {
    count
    categories

    constructor (data) {
        this.count = data.count
        this.categories = data.categories
    }
}

export {
    CategoryRequestModel,
    CategoryResponseModel,
    CategoriesResponseModel
}
