export default {
    state: {
        mediumScreen: true,
        mobile: (typeof window.orientation !== 'undefined') || (navigator.userAgent.indexOf('IEMobile') !== -1),
        mobileMenu: false
    },
    mutations: {
        setMediumScreen (state) {
            state.mediumScreen = window.matchMedia('(max-width: 991px)').matches
        },
        setMobileMenu (state, val) {
            state.mobileMenu = val
        }
    },
    actions: {
        async checkScreen ({ commit }) {
            commit('setMediumScreen')
        }
    }
}
