<template lang="pug">
  span.icon
    component(
      :is="require(`@/assets/images/${name}.svg?inline`)"
      :class="disabled ? name + '_disabled' : ''"
    )
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
}
</script>
