import axios from 'axios'
import { i18n } from '@/i18n'
import { apiURL } from '@/config/constants'

const axiosApiInstance = axios.create()

axiosApiInstance.interceptors.request.use(
    async config => {
        config.headers.common['x-custom-lang'] = i18n.locale
        config.baseURL = apiURL

        config.validateStatus = status => status >= 200 && status < 300
        return config
    },
    error => Promise.reject(error),
)

axiosApiInstance.interceptors.response.use(
    response => response,
    async error => {
        const originalConfig = error.config

        if (error.response.status === 401 && !originalConfig._retry) originalConfig._retry = true
        else return Promise.reject(error)
    }
)

export default axiosApiInstance
