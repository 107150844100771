<template lang="pug">
  main.main#app
    Header
    .main__content
      transition(name="fade" mode="out-in")
        router-view(:key="key")
    Footer
    Popups
    transition(name="fade")
      MobileMenu(
        v-if="mobileMenu"
        :class="{'mobile-menu_open': mobileMenu}"
      )
</template>

<script>
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Popups from './components/common/Popups'
import { mapState, mapActions } from 'vuex'
import MobileMenu from '@/components/MobileMenu'
import CategoryService from '@/services/category.service'

export default {
  components: {
    MobileMenu,
    Popups,
    Header,
    Footer,
  },
  computed: {
    ...mapState({
      mobileMenu: state => state.common.mobileMenu
    }),
    key () {
      return this.$route.meta.key !== undefined ? this.$route.meta.key : ''
    }
  },
  methods: {
    ...mapActions(['checkScreen']),
    async getCategories () {
      await CategoryService.getCategories()
          .then(response => {
            this.$store.commit('categories/setCategories', response.categories)
            response.categories.forEach(category => localStorage.setItem('category_' + category.name.toLowerCase(), category.id))
          })
    },
  },
  mounted () {
    this.getCategories()
    this.checkScreen()
    window.addEventListener('resize', () => this.checkScreen())
  }
}
</script>

<style lang="stylus">
.main
  position relative
  min-height 100vh
  display flex
  flex-direction column
</style>
