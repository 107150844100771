import {
    NewsRequestModel,
    NewRequestModel,
    NewUniqueRequestModel
} from '@/models/news.model'
import NewsClient from '@/clients/news.client'

export default class NewsService {
    static async getNews (data) {
        const newsRequestData = new NewsRequestModel(data)
        return await NewsClient.getNews(newsRequestData)
    }

    static async getNew (data) {
        const newRequestData = new NewRequestModel(data)
        return await NewsClient.getNew(newRequestData)
    }

    static async getNewUnique (data) {
        const newUniqueRequestData = new NewUniqueRequestModel(data)
        return await NewsClient.getNewUnique(newUniqueRequestData)
    }
}
