export default {
    namespaced: true,
    state: {
        news: []
    },
    getters: {
        newsById: state => id => state.news.find(item => item.id === Number(id))
    },
    mutations: {
        setNews (state, val) {
            state.news = val
        },
    },
    actions: {}
}
