<template lang="pug">
  label.input
    .input__field-wrapper
      input.input__field(
        :placeholder="placeholder"
        :type="type"
        :value="value"
        @input="$emit('input', $event.target.value)"
        :class="{'input__field-search': button === 'search'}"
      )

      button(
        v-if="button === 'search'"
        @click.prevent="$emit('search')"
      ).input__button
        BaseIcon(name="search")
</template>

<script>
import BaseIcon from '@/components/common/BaseIcon'

export default {
  components: { BaseIcon },
  props: {
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    button: {
      type: String,
      default: ''
    }
  }
}
</script>
