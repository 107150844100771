<template lang="pug">
  ul.locale-switcher
    li(
      v-for="locale in supportedLocales"
      :key="locale"
      @click="switchLocale(locale)"
      :class="{'locale-switcher__item_active': locale === $i18n.locale}"
    ).locale-switcher__item
      BaseImage(:path="`${locale}.png`").locale-switcher__image
      | {{ $t('language.' + locale) }}
</template>

<script>
import { Trans } from '@/plugins/Translation'
import BaseImage from '@/components/common/BaseImage'

export default {
  name: 'LocaleSwitcher',
  components: { BaseImage },
  computed: {
    supportedLocales: () => Trans.supportedLocales
  },
  methods: {
    switchLocale (locale) {
      this.$emit('switchLocale')

      if (this.$i18n.locale !== locale) {
        const to = this.$router.resolve({ params: { locale } })
        return Trans.changeLocale(locale).then(() => this.$router.push(to.location))
      }
    }
  }
}
</script>
