import common from './common'
import user from './user'
import news from './news'
import products from './products'
import categories from './categories'

export default {
	common,
	user,
	news,
	products,
	categories
}
