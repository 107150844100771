<template lang="pug">
  .mobile-menu
    .mobile-menu__block
      .mobile-menu__closer(@click.prevent="closeMenu")
        BaseIcon(name="close")
      ul.mobile-menu__list
        li.mobile-menu__item
          router-link(:to="$i18nRoute({ name: 'About' })") {{ $t('menu.about') }}
        li.mobile-menu__item(v-if="categories.length")
          ExpandableItem
            template(v-slot:toggle)
              h3.expandable__title
                span {{ $t('menu.products') }}
                BaseIcon(name="chevron" class="expandable__chevron")
            template(v-slot:expandable)
              li.mobile-menu__item(
                v-for="category in categories"
                :key="category.id"
              )
                router-link(
                  :to=`$i18nRoute({
                        name: 'ProductsCategory',
                        params: {
                            category: category.name.toLowerCase(),
                            category_id: category.id
                        }
                    })`
                ) {{ category.name | toCapitalize }}
        li.mobile-menu__item
          router-link(:to="$i18nRoute({ name: 'News' })") {{ $t('menu.news') }}
        li.mobile-menu__item
          router-link(:to="$i18nRoute({ name: 'CheckCode' })") {{ $t('menu.check_code') }}
        li.mobile-menu__item
          router-link(:to="$i18nRoute({ name: 'Contact' })") {{ $t('menu.contact') }}

    .mobile-menu__wrapper(@click.prevent="closeMenu")
</template>

<script>
import ExpandableItem from '@/components/common/ExpandableItem'
import BaseIcon from '@/components/common/BaseIcon'
import { mapState } from 'vuex'

export default {
  name: 'menu',
  components: { ExpandableItem, BaseIcon },
  computed: {
    ...mapState({
      categories: state => state.categories.categories
    }),
  },
  methods: {
    toCapitalize (value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    closeMenu () {
      this.$store.commit('setMobileMenu', false)
    }
  },
}
</script>
