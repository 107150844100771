class NewsRequestModel {
    limit
    offset
    lang

    constructor (data) {
        this.limit = data.limit
        this.offset = data.offset
        this.lang = data.lang
    }
}

class NewsResponseModel {
    count
    news

    constructor (data) {
        this.count = data.count
        this.news = data.news
    }
}

class NewRequestModel {
    new_id

    constructor (data) {
        this.new_id = data.new_id
    }
}

class NewResponseModel {
    count
    news

    constructor (data) {
        this.count = data.count
        this.news = data.news
    }
}

class NewUniqueRequestModel {
    new_type

    constructor (data) {
        this.new_type = data.new_type
    }
}

class NewUniqueResponseModel {
    count
    news

    constructor (data) {
        this.count = data.count
        this.news = data.news
    }
}

export {
    NewsRequestModel,
    NewsResponseModel,
    NewRequestModel,
    NewResponseModel,
    NewUniqueRequestModel,
    NewUniqueResponseModel
}
