export default {
    namespaced: true,
    state: {
        categories: [],
        categories_loaded: false
    },
    getters: {
        manufacturerIdByName: state => value => {
            if (value) {
                const query = value.replace(/-/g, ' ')
                let manufacture_id = null

                state.categories.forEach(category => {
                    category.manufacturers.forEach(item => {
                        if (item.name.toLowerCase() === query.toLowerCase()) manufacture_id = item.id
                    })
                })

                return manufacture_id
            } else return null
        },
        categoryIdByName: state => value => {
            let category_id = null
            state.categories.forEach(category => {
                if (category.name.toLowerCase() === value.toLowerCase()) category_id = category.id
            })
            return category_id
        }
    },
    mutations: {
        setCategories (state, val) {
            state.categories = val
            state.categories_loaded = true
        },
    },
    actions: {
        setCategoriesAction ({ commit }, payload) {
            commit('setCategories', payload)
        }
    }
}
