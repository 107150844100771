import axiosApiInstance from '@/config/axios.config'

import { CategoryResponseModel, CategoriesResponseModel } from '@/models/category.model'

export default class CategoryClient {
    static async getCategories () {
        const response = await axiosApiInstance.get('/v1/shop/categories')
        return new CategoriesResponseModel(response.data)
    }

    static async getCategory (data) {
        const response = await axiosApiInstance.get('/v1/shop/categories', { params: data })
        return new CategoryResponseModel(response.data)
    }
}
