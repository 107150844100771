<template lang="pug">
  footer.footer
    .container
      .row
        .footer__container
          .footer__information.col-lg-4.col-md-4
            router-link(to="/").footer__logo
              img(:src="require('@/assets/images/logotype.png')")
            p.footer__information-text {{ $t('layout.footer.description') }}

          .footer__menu.col-lg-4.col-md-4.col-sm-12(v-if="!isMobile")
            h4.footer__title {{ $t('layout.footer.menu_title') }}

            ul.footer__list
              li.footer__item
                router-link(:to="$i18nRoute({ name: 'About' })") {{ $t('menu.about_us') }}
              li.footer__item
                router-link(:to="$i18nRoute({ name: 'Delivery' })") {{ $t('menu.delivery_information') }}
              li.footer__item
                router-link(:to="$i18nRoute({ name: 'Privacy' })") {{ $t('menu.privacy_policy') }}
              li.footer__item
                router-link(:to="$i18nRoute({ name: 'TermsAndConditions' })") {{ $t('menu.terms_conditions') }}
              li.footer__item
                router-link(:to="$i18nRoute({ name: 'Information' })") {{ $t('menu.information') }}

          ExpandableItem(v-if="isMobile").footer__expandable
            template(v-slot:toggle)
              h3.footer__expandable-title.expandable__title
                span {{ $t('layout.footer.menu_title') }}
                BaseIcon(name="chevron" class="expandable__chevron")
            template(v-slot:expandable)
              .footer__menu
                ul.footer__list
                  li.footer__item
                    router-link(:to="$i18nRoute({ name: 'About' })") {{ $t('menu.about_us') }}
                  li.footer__item
                    router-link(:to="$i18nRoute({ name: 'Delivery' })") {{ $t('menu.delivery_information') }}
                  li.footer__item
                    router-link(:to="$i18nRoute({ name: 'Privacy' })") {{ $t('menu.privacy_policy') }}
                  li.footer__item
                    router-link(:to="$i18nRoute({ name: 'TermsAndConditions' })") {{ $t('menu.terms_conditions') }}
                  li.footer__item
                    router-link(:to="$i18nRoute({ name: 'Information' })") {{ $t('menu.information') }}

          .footer__contact.col-lg-4.col-md-4.col-sm-12(v-if="!isMobile")
            .footer__contact-item
              .footer__contact-icon
                img(:src="require('@/assets/images/pin.png')")
              .footer__contact-content
                .footer__contact-title {{ $t('layout.footer.address') }}
                .footer__contact-description(v-html="$t('layout.footer.address_info')")

            .footer__contact-item
              .footer__contact-icon
                img(:src="require('@/assets/images/email.png')")
              .footer__contact-content
                .footer__contact-title {{ $t('layout.footer.social') }}
                a.footer__contact-description(:href="'mailto:' + $t('layout.footer.mail_info')" target="_blank" v-html="$t('layout.footer.mail_info')")

            .footer__contact-item
              .footer__contact-icon
                img(:src="require('@/assets/images/phone.png')")
              .footer__contact-content
                .footer__contact-title {{ $t('layout.footer.contact') }}
                a.footer__contact-description(:href="'tel:' + $t('layout.footer.phone_info')" target="_blank" v-html="$t('layout.footer.phone_info')")

          ExpandableItem(v-if="isMobile").footer__expandable
            template(v-slot:toggle)
              h3.footer__expandable-title.expandable__title
                span {{ $t('layout.footer.store_information') }}
                BaseIcon(name="chevron" class="expandable__chevron")
            template(v-slot:expandable)
              .footer__contact
                .footer__contact-item
                  .footer__contact-icon
                    img(:src="require('@/assets/images/pin.png')")
                  .footer__contact-content
                    .footer__contact-title {{ $t('layout.footer.address') }}
                    .footer__contact-description(v-html="$t('layout.footer.address_info')")
                .footer__contact-item
                  .footer__contact-icon
                    img(:src="require('@/assets/images/email.png')")
                  .footer__contact-content
                    .footer__contact-title {{ $t('layout.footer.social') }}
                    a.footer__contact-description(:href="'mailto:' + $t('layout.footer.mail_info')" target="_blank" v-html="$t('layout.footer.mail_info')")
                .footer__contact-item
                  .footer__contact-icon
                    img(:src="require('@/assets/images/phone.png')")
                  .footer__contact-content
                    .footer__contact-title {{ $t('layout.footer.contact') }}
                    a.footer__contact-description(:href="'tel:' + $t('layout.footer.phone_info')" target="_blank" v-html="$t('layout.footer.phone_info')")
</template>

<script>
import BaseIcon from '@/components/common/BaseIcon'
import ExpandableItem from '@/components/common/ExpandableItem'
import { mapState } from 'vuex'

export default {
  name: 'Footer',
  components: { BaseIcon, ExpandableItem },
  computed: {
    ...mapState({
      isMobile: state => state.common.mediumScreen
    })
  }
}
</script>
