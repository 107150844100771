import { CategoryRequestModel } from '@/models/category.model'
import CategoryClient from '@/clients/category.client'

export default class CategoryService {
    static async getCategories () {
        return await CategoryClient.getCategories()
    }

    static async getCategory (data) {
        const categoryRequestData = new CategoryRequestModel(data)
        return await CategoryClient.getCategory(categoryRequestData)
    }
}
