import Vue from 'vue'

Vue.filter('formatDate', function (value, date) {
    if (value) {
        if (date === 'short') {
            return new Date(value).toLocaleString([], {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
            }).replace(',', '')
        } else {
            return new Date(value).toLocaleString([], {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
                hour: '2-digit',
                minute: '2-digit'
            }).replace(',', '')
        }
    }
})

Vue.filter('toCapitalize', function (value) {
    return value.charAt(0).toUpperCase() + value.slice(1)
})

