export default {
    namespaced: true,
    state: {
        products: []
    },
    getters: {
        productById: state => id => state.products.find(item => item.id === Number(id))
    },
    mutations: {
        setProducts (state, val) {
            state.products = val
        },
    },
    actions: {}
}
