import axiosApiInstance from '@/config/axios.config'

import { NewsResponseModel, NewResponseModel, NewUniqueResponseModel } from '@/models/news.model'

export default class NewsClient {
    static async getNews (data) {
        const response = await axiosApiInstance.get('/v1/shop/news', { params: data })
        return new NewsResponseModel(response.data)
    }

    static async getNew (data) {
        console.log(data)
        const response = await axiosApiInstance.get('/v1/shop/news', { params: data })
        return new NewResponseModel(response.data)
    }

    static async getNewUnique (data) {
        const response = await axiosApiInstance.get('/v1/shop/news', { params: data })
        return new NewUniqueResponseModel(response.data)
    }
}
