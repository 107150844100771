import Vue from 'vue'
import Router from 'vue-router'
import { Trans } from '@/plugins/Translation'

function load (component) {
    return () => import(`@/views/${ component }.vue`)
}

Vue.use(Router)

let routes = [
    {
        path: '/:locale',
        component: {
            template: '<router-view></router-view>'
        },
        beforeEnter: Trans.routeMiddleware,
        children: [
            {
                path: '',
                name: 'Index',
                component: load('Index'),
                meta: {
                    key: 1
                }
            },
            {
                path: 'about',
                name: 'About',
                component: load('About'),
                meta: {
                    key: 2
                }
            },
            {
                path: 'products',
                name: 'Products',
                component: load('Products'),
                meta: {
                    key: 3
                },
                props: true,
                query: true,
                children: [
                    {
                        path: ':category',
                        name: 'ProductsCategory',
                        component: load('Products'),
                        meta: {
                            key: 33
                        },
                        props: true,
                        query: true,
                    },
                    {
                        path: ':category/:manufacture',
                        name: 'ProductsCategoryManufacture',
                        component: load('Products'),
                        meta: {
                            key: 34
                        },
                        props: true,
                        query: true,
                    }
                ]
            },
            {
                path: 'products/:category/:manufacture/:name/:id',
                name: 'Product',
                component: load('Product'),
                meta: {
                    key: 35
                },
                props: true
            },
            {
                path: 'news',
                name: 'News',
                component: load('News'),
                meta: {
                    key: 7
                }
            },
            {
                path: 'news/:id',
                name: 'NewsCard',
                component: load('NewsCard'),
                meta: {
                    parent: 'News',
                    key: 8
                }
            },
            {
                path: 'check-code',
                name: 'CheckCode',
                component: load('CheckCode'),
                meta: {
                    key: 9
                }
            },
            {
                path: 'contact',
                name: 'Contact',
                component: load('Contact'),
                meta: {
                    key: 10
                }
            },
            {
                path: 'information',
                name: 'Information',
                component: load('Information'),
                meta: {
                    key: 11
                }
            },
            {
                path: 'terms-and-conditions',
                name: 'TermsAndConditions',
                component: load('TermsAndConditions'),
                meta: {
                    key: 12
                }
            },
            {
                path: 'delivery',
                name: 'Delivery',
                component: load('Delivery'),
                meta: {
                    key: 13
                }
            },
            {
                path: 'privacy',
                name: 'Privacy',
                component: load('Privacy'),
                meta: {
                    key: 14
                }
            },
        ]
    },
    {
        path: '*',
        redirect: () => Trans.defaultLocale,
        meta: {
            key: 101
        }
    }
]

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
