<template lang="pug">
  header.header
    .header__head
      .container
        .row.header__row
          .header__head-left.col-2
            .language-switcher(
              :class="{'language-switcher_open': languageOpen}"
            )
              span(@click="openLanguage")
                | {{ $t('layout.header.language') }}
                BaseIcon(name="chevron").language-switcher__chevron
              transition(name="fade")
                LocalSwitcher(
                  v-if="languageOpen"
                  v-on:switchLocale="openLanguage"
                )

          .header__head-center.col-9(v-if="news_item")
            .text(v-html="sliceNewsHead(news_item.head)" )
            router-link(
              :to="$i18nRoute({ name: 'NewsCard', params: { id: news_item.id }})"
            ).header__head-center__link {{ $t('learn_more') }}

    .header__body
      .container
        .row.header__row
          .header__body-left.col-4
            .header__info-text(v-html="$t('layout.header.all_designed')")

            .header__mobile
              button(@click.prevent="openMenu").header__mobile-burger
                BaseIcon(name="burger")
              button(@click.preven="openSearch").header__mobile-search
                BaseIcon(name="search")

          .header__logo.col-4
            router-link(to="/").logo
              img(:src="require('@/assets/images/logotype.png')")

          .header__info.col-4
            .header__info-text(v-html="$t('layout.header.all_designed')")

            .header__mobile
              button(@click.preven="openAccount").header__mobile-account
                BaseIcon(name="account")
              .language-switcher
    .header__footer
      transition(name="fade" mode="in-out")
        .header__footer-content(
          :class="{'header__footer_fixed': fixedMenu}"
        )
          .container
            .row.header__row
              .col-12.header__menu
                ul.header__menu-list
                  li.header__menu-item
                    router-link(:to="$i18nRoute({ name: 'About' })") {{ $t('menu.about') }}
                  li.header__menu-item(v-if="categories.length")
                    span {{ $t('menu.products') }}
                    .header__menu-item__dropdown
                      ul.header__menu-list
                        li.header__menu-item(
                          v-for="category in categories"
                          :key="category.id"
                        )
                          router-link(
                            :to=`$i18nRoute({
                                   name: 'ProductsCategory',
                                   params: {
                                       category: category.name.toLowerCase(),
                                       category_id: category.id
                                   }
                                  })`
                          ) {{ category.name | toCapitalize }}

                          ul.header__menu-list
                            li.header__menu-item(
                              v-for="manufacturer in category.manufacturers"
                              :key="'manufacturer' + manufacturer.id"
                            )
                              router-link(
                                :to=`$i18nRoute({
                                        name: 'ProductsCategoryManufacture',
                                        params: {
                                            category: category.name.toLowerCase(),
                                            manufacture: productName(manufacturer.name),
                                            category_id: category.id,
                                            manufacturer_id: manufacturer.id,
                                        }
                                    })`
                              ) {{ manufacturer.name | toCapitalize }}
                  li.header__menu-item
                    router-link(:to="$i18nRoute({ name: 'News' })") {{ $t('menu.news') }}
                  li.header__menu-item
                    router-link(:to="$i18nRoute({ name: 'CheckCode' })") {{ $t('menu.check_code') }}
                  li.header__menu-item
                    router-link(:to="$i18nRoute({ name: 'Contact' })") {{ $t('menu.contact') }}
</template>

<script>
import CustomInput from '@/components/common/CustomInput'
import BaseIcon from '@/components/common/BaseIcon'
import LocalSwitcher from '@/components/LocalSwitcher'
import { mapState } from 'vuex'
import NewsService from '@/services/news.service'

export default {
  name: 'Header',
  components: { LocalSwitcher, BaseIcon, CustomInput },
  data: () => ({
    search: '',
    fixedMenu: false,
    languageOpen: false,
    news_item: null
  }),
  computed: {
    ...mapState({
      categories: state => state.categories.categories
    })
  },
  methods: {
    sliceNewsHead (string) {
      if (string.length > 50) {
        return string.slice(0, 50) + '...'
      }
      return string
    },
    toCapitalize (value) {
      return value.charAt(0).toUpperCase() + value.slice(1)
    },
    openAccount () {
      console.log('Open account')
    },
    openMenu () {
      this.$store.commit('setMobileMenu', true)
    },
    openSearch () {
      console.log('search open')
    },
    openLanguage () {
      this.languageOpen = !this.languageOpen
    },
    productName (value) {
      return value.replace(/ /g, '-').toLowerCase()
    },
    async getNewsItem () {
      await NewsService.getNewUnique({ new_type: 1 })
          .then(response => this.news_item = response.news[0])
    }
  },
  mounted () {
    window.document.onscroll = () => {
      const menu = document.querySelector('.header__footer-content')
      this.fixedMenu = window.scrollY - menu.scrollHeight > menu.offsetTop + menu.scrollHeight
    }

    this.getNewsItem()
  }
}
</script>

