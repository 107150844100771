var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mobile-menu"},[_c('div',{staticClass:"mobile-menu__block"},[_c('div',{staticClass:"mobile-menu__closer",on:{"click":function($event){$event.preventDefault();return _vm.closeMenu.apply(null, arguments)}}},[_c('BaseIcon',{attrs:{"name":"close"}})],1),_c('ul',{staticClass:"mobile-menu__list"},[_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{attrs:{"to":_vm.$i18nRoute({ name: 'About' })}},[_vm._v(_vm._s(_vm.$t('menu.about')))])],1),(_vm.categories.length)?_c('li',{staticClass:"mobile-menu__item"},[_c('ExpandableItem',{scopedSlots:_vm._u([{key:"toggle",fn:function(){return [_c('h3',{staticClass:"expandable__title"},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.products')))]),_c('BaseIcon',{staticClass:"expandable__chevron",attrs:{"name":"chevron"}})],1)]},proxy:true},{key:"expandable",fn:function(){return _vm._l((_vm.categories),function(category){return _c('li',{key:category.id,staticClass:"mobile-menu__item"},[_c('router-link',{attrs:{"to":_vm.$i18nRoute({
                      name: 'ProductsCategory',
                      params: {
                          category: category.name.toLowerCase(),
                          category_id: category.id
                      }
                  })}},[_vm._v(_vm._s(_vm._f("toCapitalize")(category.name)))])],1)})},proxy:true}],null,false,3261814616)})],1):_vm._e(),_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{attrs:{"to":_vm.$i18nRoute({ name: 'News' })}},[_vm._v(_vm._s(_vm.$t('menu.news')))])],1),_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{attrs:{"to":_vm.$i18nRoute({ name: 'CheckCode' })}},[_vm._v(_vm._s(_vm.$t('menu.check_code')))])],1),_c('li',{staticClass:"mobile-menu__item"},[_c('router-link',{attrs:{"to":_vm.$i18nRoute({ name: 'Contact' })}},[_vm._v(_vm._s(_vm.$t('menu.contact')))])],1)])]),_c('div',{staticClass:"mobile-menu__wrapper",on:{"click":function($event){$event.preventDefault();return _vm.closeMenu.apply(null, arguments)}}})])
}
var staticRenderFns = []

export { render, staticRenderFns }