<template lang="pug">
  .expandable
    .expandable__toggle(
      v-on="!off ? {click: () => onExpandableItemClick()} : {}"
      :class="{'expandable__toggle_active' : isOpen, 'expandable__toggle_disabled': isDisabled}")
      slot(name="toggle")
    .expandable__body(ref="body")
      .expandable__layout(ref="layout")
        slot(name="expandable")
</template>

<script>
export default {
  props: {
    mobileOff: {
      type: Boolean,
      default: false
    },
    open: {
      type: Boolean,
      default: false,
    },
    recalc: {
      type: Boolean
    }
  },
  data: () => ({
    off: false,
    isOpen: false,
    isDisabled: false
  }),
  watch: {
    open () {
      if(this.open) {
        this.setHeight()
      }
    },
    recalc() {
      this.$nextTick(() => {
        this.setHeight()
      });

    }
  },
  mounted() {
    if (this.mobileOff) {
      this.off = window.matchMedia('(max-width: 660px)').matches
    }
    if (!this.off) {
      window.addEventListener('resize', this.onResize)
    }
    if(this.open) {
      this.setHeight()
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onExpandableItemClick () {
      if (this.isOpen) {
        this.$refs.body.removeAttribute('style')
        this.isOpen = false
      } else {
        this.setHeightSmooth()
        this.isDisabled = true
      }
    },
    setHeight() {
      this.$refs.body.style.cssText = `overflow:visible; height:${ this.$refs.layout.offsetHeight }px;transition:none`
      this.isOpen = true
    },
    setHeightSmooth() {
      this.isOpen = true
      this.$refs.body.style.cssText = `height:${ this.$refs.layout.offsetHeight }px`

      setTimeout(() => {
        if(this.$refs.body) {
          this.$refs.body.style.overflow = 'visible'
          this.isDisabled = false
        }
      }, 250)
    },
    onResize () {
      if (this.isOpen) {
        this.setHeight()
      }
    }
  },
}
</script>
