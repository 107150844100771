<template lang="pug">
  img(:src="imagePath(path)" :alt="alt")
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    path: {
      type: String,
      default: ''
    },
    alt: {
      type: String,
      default: ''
    },
    external: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    imagePath (path) {
      return this.external ? process.env.VUE_APP_BASE_URL + path : require('@/assets/images/' + path)
    }
  }
}
</script>
