import Vue from 'vue'
import App from '@/App.vue'
import store from '@/store'
import router from '@/router'
import vSelect from 'vue-select'
import { i18n } from '@/i18n'
import { Trans } from '@/plugins/Translation'
import Paginate from 'vuejs-paginate'
import './filters'
import '@/config/axios.config'
import 'vue-select/dist/vue-select.css'

Vue.component('v-select', vSelect)
Vue.component('paginate', Paginate)

Vue.prototype.$i18nRoute = Trans.i18nRoute.bind(Trans)
Vue.config.productionTip = false

new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
